import React, { useState, useRef, useEffect, forwardRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faMinus,
  faCheckCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons"
import { dspOptions, outcomes } from "./data"
import submitPMPForm from "./send-pmp-contact-form"
import ReCaptchaToken from "../re-captcha-token"
import styled, { keyframes } from "styled-components"

const fadeOut = keyframes`
  from { opacity: 1; height: auto; transform: translateY(0); }
  to { opacity: 0; height: 0; transform: translateY(-10px); overflow: hidden; }
`

const fadeIn = keyframes`
  from { opacity: 0; height: 0; transform: translateY(-10px); overflow: hidden; }
  to { opacity: 1; height: auto; transform: translateY(0); }
`

const StepContainer = styled.div`
  position: relative;
  display: flex;
  padding-left: 20px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 18px;
    width: 12px;
    height: 12px;
    background-color: ${props => (props.active ? "#0A6CFF" : "#ccc")};
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0.33em;
    top: 30px;
    bottom: -30px;
    width: 1px;
    background: #0a6cff;
    display: ${props => (props.isLast ? "none" : "block")};
  }

  &:last-of-type::after {
    display: none;
  }
`

const StepContent = styled.div`
  width: 100%;
`

export const StepButtonsContainer = styled.div`
  animation: ${props => (props.isClosing ? fadeOut : fadeIn)} 0.3s ease-out;
  height: ${props => (props.isClosing ? "0" : "auto")};
  overflow: hidden;
  transition: height 0.3s ease-out;
`

export const FadeInMessage = styled.div`
  animation: ${fadeIn} 0.5s ease-out;
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 300;
  color: ${props => (props.theme === "light" ? "#2b1d7a" : "#fff")};
  margin-top: 20px;
  opacity: ${props => (props.show ? "1" : "0")};
  transition: opacity 0.5s ease-out;
`

const PMPDealForm = ({ theme = "default", formPlacement }) => {
  const pmpForm = useRef()
  const step2LabelRef = useRef()

  const [activeStep, setActiveStep] = useState(1)
  const [closingStep, setClosingStep] = useState(null)
  const [selectedOutcome, setSelectedOutcome] = useState(null)
  const [selectedDSP, setSelectedDSP] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [showMessage, setShowMessage] = useState(false)
  const [step2LabelHeight, setStep2LabelHeight] = useState(0)

  const toggleStep = step => {
    if (activeStep === step) {
      setClosingStep(step)
      setTimeout(() => {
        setActiveStep(null)
        setClosingStep(null)
      }, 300)
    } else {
      setClosingStep(activeStep)
      setTimeout(
        () => {
          setClosingStep(null)
          setActiveStep(step)
        },
        activeStep ? 300 : 0
      )
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)
    setError("")

    const { success, error } = await submitPMPForm(pmpForm.current)

    setIsLoading(false)

    if (!success) {
      setError(error)
      return
    }

    setClosingStep(2)
    setTimeout(() => {
      setActiveStep(null)
      setShowMessage(true)
    }, 300)

    setTimeout(() => {
      setShowMessage(false)
    }, 4500)
  }

  useEffect(() => {
    const updateLineHeight = () => {
      if (step2LabelRef.current) {
        const rect = step2LabelRef.current.getBoundingClientRect()
        setStep2LabelHeight(
          rect.top -
            step2LabelRef.current.offsetParent.getBoundingClientRect().top
        )
      }
    }

    updateLineHeight()
    window.addEventListener("resize", updateLineHeight)
    return () => window.removeEventListener("resize", updateLineHeight)
  }, [activeStep])

  return (
    <div className={`pmp-form ${theme} blurred-background form-column`}>
      <h2 className="title is-5 form-title has-text-weight-medium mb-4">
        Request PMP Deal ID
      </h2>

      <Step
        title="Step 1"
        subtitle={
          selectedOutcome
            ? `Desired Outcome: ${selectedOutcome}`
            : "Desired Outcome"
        }
        step={1}
        activeStep={activeStep}
        toggleStep={toggleStep}
      >
        <StepButtonsContainer isClosing={closingStep === 1}>
          <div className="columns is-multiline mt-4">
            {outcomes.map(outcome => (
              <div
                key={outcome}
                className="column is-half-desktop is-full-mobile"
              >
                <button
                  className={`button form-option is-outlined is-fullwidth is-medium ${
                    selectedOutcome === outcome ? "selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedOutcome(outcome)
                    toggleStep(2)
                  }}
                >
                  {outcome}
                  {selectedOutcome === outcome && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="selected-check"
                    />
                  )}
                </button>
              </div>
            ))}
          </div>
        </StepButtonsContainer>
      </Step>

      <Step
        step2LabelHeight={step2LabelHeight}
        title="Step 2"
        subtitle="Company Details"
        ref={step2LabelRef}
        step={2}
        activeStep={activeStep}
        toggleStep={toggleStep}
        isLast={true}
      >
        <StepButtonsContainer isClosing={closingStep === 2}>
          <form ref={pmpForm} onSubmit={handleSubmit} className="mt-3">
            <input type="hidden" name="formPlacement" value={formPlacement} />
            <input type="hidden" name="outcome" value={selectedOutcome || ""} />

            <label htmlFor="dsp" className="label">
              Select DSP
            </label>
            <div className="select is-fullwidth">
              <select
                id="dsp"
                name="dsp"
                value={selectedDSP}
                onChange={e => setSelectedDSP(e.target.value)}
              >
                <option value="">Select a DSP</option>
                {dspOptions.map(dsp => (
                  <option key={dsp} value={dsp}>
                    {dsp}
                  </option>
                ))}
              </select>
            </div>

            <label htmlFor="name" className="label">
              Name
            </label>
            <input
              id="name"
              type="text"
              name="name"
              className="input"
              placeholder="e.g. John Doe"
              value={name}
              onChange={e => setName(e.target.value)}
            />

            <label htmlFor="email" className="label">
              Work Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              className="input"
              placeholder="example@email.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />

            <label htmlFor="company" className="label">
              Company
            </label>
            <input
              id="company"
              name="company"
              type="text"
              className="input"
              placeholder="e.g. Apple"
              value={company}
              onChange={e => setCompany(e.target.value)}
            />

            <div className="mt-4">
              <ReCaptchaToken style={{ marginTop: 15 }} />
            </div>

            <button
              type="submit"
              className="button is-fullwidth mt-5 submit-btn has-text-white"
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin size="lg" />
              ) : (
                "Submit"
              )}
            </button>

            {error && <p className="has-text-danger mt-2 is-size-2">{error}</p>}
          </form>
        </StepButtonsContainer>
      </Step>

      {showMessage && (
        <FadeInMessage show={showMessage} theme={theme}>
          We’ll be in touch shortly!
        </FadeInMessage>
      )}
    </div>
  )
}

const Step = forwardRef(
  (
    { title, subtitle, step, activeStep, toggleStep, children, isLast },
    ref
  ) => (
    <StepContainer active={activeStep === step} isLast={isLast}>
      <StepContent>
        <div className="column is-full step-content">
          <div
            className="is-flex is-justify-content-space-between is-align-items-center"
            onClick={() => toggleStep(step)}
            style={{ cursor: "pointer" }}
          >
            <div>
              <h3 className="step-title is-size-7 is-uppercase has-text-weight-light">
                {title}
              </h3>
              <h3 className="subtitle is-5 has-text-weight-light">
                {subtitle}
              </h3>
            </div>
            <button
              className="button is-small expand-btn is-text ml-4"
              onClick={e => {
                e.stopPropagation()
                toggleStep(step)
              }}
            >
              <FontAwesomeIcon icon={activeStep === step ? faMinus : faPlus} />
            </button>
          </div>
          {activeStep === step && children}
        </div>
      </StepContent>
    </StepContainer>
  )
)

export default PMPDealForm
